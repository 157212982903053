import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { SignUpService } from '../services/signup.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { ExperimentService } from '../services/experiment.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user.edit.component.html'
})
export class UserEditComponent implements OnInit {

  public queryParams;
  experimentForm = null;


  roleList: any[] = [
    {
      key: 'ROLE_SUPERADMIN',
      value: 'Super Admin'
    },
    {
      key: 'ROLE_ADMIN',
      value: 'Admin'
    },
    {
      key: 'ROLE_USER',
      value: 'User'
    }
  ];


  constructor(private fb: FormBuilder,
    public toastr: ToastrManager, public router: Router,
    public userService: UserService,
    private route: ActivatedRoute) {
    route.queryParams
      .subscribe((params) => this.queryParams = params);
  }
  ngOnInit() {
    console.log(this.queryParams);
    this.experimentForm = this.fb.group({
      email: [this.queryParams.email, Validators.required],
      name: [this.queryParams.name, Validators.required],
      roles: [this.queryParams.role, Validators.required]
    });






  }

  submit() {
    console.log("data" + this.experimentForm.value)
    this.userService.update(this.experimentForm.value).subscribe((data: any) => {
      this.toastr.successToastr('User Updated Successfully', 'Success!');
      this.router.navigate(['user-management']);
    },
      errorResponse => {
        console.log(errorResponse);
        let err1 = "";

        if (errorResponse.error.fieldErrors != null) {
          errorResponse.error.fieldErrors.forEach(function (err) {
            err1 = err1 + err.fieldError + "\n"
          });
          this.toastr.errorToastr(err1, 'Error!')

        } else {
          this.toastr.errorToastr(errorResponse.message, 'Error!')
        }
      }
    )
  }


}

