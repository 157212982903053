
import { Component, OnDestroy } from '@angular/core';
import { LoaderService } from '../services/loader.service';

@Component({
	selector: 'app-loader',
	templateUrl: './loading.component.html',
	styleUrls: ['./loading.css']
})

export class LoadingComponent {


	constructor(public loaderService: LoaderService) { }

	ngOnInit() {
	}
  
	get loaderEnabled() {
	  return this.loaderService.loaderEnabled;
	}
}
