import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SignUpService } from '../services/signup.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { SignInService } from '../services/signin.service';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-signout',
  templateUrl: './signout.component.html',
  styleUrls: ['./signout.component.css']
})
export class SignOutComponent implements OnInit {


  constructor(private authService: AuthService, 
    public toastr: ToastrManager, public router: Router) { }
  ngOnInit() {
    
    this.authService.signOut().subscribe(data => {
      this.toastr.successToastr('Successfully logout', 'Success!')
      this.authService.removeLocalStorage();
      this.router.navigate(['signin']);  
    },
      errorResponse => {
       //errorResponse.error.message;
       this.toastr.errorToastr('Something went wrong', 'Error!')
        
      }
    )
    
  }

 


}

