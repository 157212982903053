import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Neuraltone';

  constructor(private authservice: AuthService, public router: Router) { }
  isLoggedIn$: Observable<boolean>;
  ngOnInit() {
    this.isLoggedIn$ = this.authservice.isUserLoggedIn;
  }

  logout(){
    this.authservice.removeLocalStorage();
    this.router.navigate(['signin']);
  }

}
