import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router';
import { PlayerService } from '../services/player.service';
import { DatePipe } from '@angular/common';
import * as fileSaver from 'file-saver'
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  userDetailsForm = null;
  userDetails = null;
  userDetailsCount: number = 0;
  playCounter: number = 0;
  limit: number = 20;
  offset: number = 1;
  prevF: boolean = true;
  firstF: boolean = true;
  nextF: boolean = false;
  lastF: boolean = false;
  showPagination: boolean = false;
  constructor(private fb: FormBuilder,
    public toastr: ToastrManager, public router: Router,
    public userService: UserService) { }


  ngOnInit() {
    this.userDetailsForm = this.fb.group({
      email: ['', Validators.required]
    });
    this.userService.getUserDetails(this.userDetailsForm.value.email, 'ROLE_ADMIN,ROLE_SUPERADMIN', this.limit,this.offset).subscribe((data: any) => {
      this.userDetails = data.users;
      this.userDetailsCount = data.pages;
      if (this.userDetailsCount > 0) {
        this.showPagination = true;
        if (this.limit >= (this.userDetailsCount * this.limit)) {
          this.lastF = true;
          this.nextF = true;
        }
      } else {
        this.firstF = true;
        this.prevF = true;
        this.lastF = true;
        this.nextF = true;
      }
      
    },
      errorResponse => {
        console.log(errorResponse.error);
        this.toastr.errorToastr(errorResponse.error.message, 'Error!')
      }
    );
  }

  getRole(role: string) {
    if ('ROLE_SUPERADMIN' == role) {
      return 'Super Admin';
    } else if ('ROLE_ADMIN' == role) {
      return 'Admin';
    } else {
      return 'User';
    }

  }

  first() {
    this.playCounter = 1;
    this.offset = 1;
    this.firstF = true;
    this.prevF = true;
    this.submit(false);
    if (this.lastF) {
      this.lastF = false;
    }
    if (this.nextF) {
      this.nextF = false;
    }
  }
  last() {
    this.playCounter = this.userDetailsCount;
    this.offset = this.playCounter;
    this.lastF = true;
    this.nextF = true;
    this.submit(false);
    if (this.offset > 1) {
      this.firstF = false;
      this.prevF = false;
    }
  }
  next() {
    if (this.offset < this.userDetailsCount) {
      this.offset = this.offset + 1;
      this.submit(false);
    } else {
      this.nextF = true;
      this.lastF = true;
    }
    if (this.offset == this.userDetailsCount) {
      this.nextF = true;
      this.lastF = true;
    }

    if (this.offset > 1) {
      this.firstF = false;
      this.prevF = false;
    }


  }
  prev() {
    if (this.offset != 1) {
      this.offset = this.offset - 1;
      this.submit(false);
    } else {
      this.firstF = true;
      this.prevF = true;
    }
    if(this.offset == 1){
      this.firstF = true;
      this.prevF = true;
    }
    if (this.offset < this.userDetailsCount) {
      this.nextF = false;
      this.lastF = false;
    }

  }

  submit(flag:boolean) {
    console.log("data" + this.userDetailsForm.value)
    if (flag) {
      this.offset = 1;
      this.prevF=true;
      this.firstF=true;
      this.lastF = false;
        this.nextF = false;
    }
    this.userService.getUserDetails(encodeURIComponent(this.userDetailsForm.value.email), 'ROLE_ADMIN,ROLE_SUPERADMIN', this.limit, this.offset).subscribe((data: any) => {
      this.userDetails = data.users;
      this.userDetailsCount = data.pages;
      if (this.userDetailsCount > 0) {
        this.showPagination = true;
        if (this.limit >= (this.userDetailsCount * this.limit)) {
          this.lastF = true;
          this.nextF = true;
        }
      } else {
        this.showPagination = false;
        this.firstF = true;
        this.prevF = true;
        this.lastF = true;
        this.nextF = true;
      }
    },
      errorResponse => {
        console.log(errorResponse.error);
        this.toastr.errorToastr(errorResponse.error.message, 'Error!')
      }
    )
  }


}

