import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { SignInService } from '../services/signin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { PasswordService } from '../services/password.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset.password.component.html'
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm = null;
 token=null;
  constructor(private route: ActivatedRoute, private fb: FormBuilder, private service: PasswordService,
    public toastr: ToastrManager, public router: Router, public authService: AuthService) { }
  ngOnInit() {
    this.token = this.route.snapshot.paramMap.get('token');
    this.resetPasswordForm = this.fb.group({
      password: ['', Validators.required],
      confirmPassword:  ['', Validators.required]
    });
  }

  resetPasswordSubmit() {
    this.service.confirmPassword(this.token,this.resetPasswordForm.value).subscribe((data: any) => {
      this.toastr.successToastr('Password Reset Successfully', 'Success!');
      this.router.navigate(['signin']);
    },
      errorResponse => {
        let err1 = "";
        if (errorResponse.error.fieldErrors != null && errorResponse.error.fieldErrors.length > 0 ) {
          errorResponse.error.fieldErrors.forEach(function (err) {
            err1 = err1 + err.fieldError + "\n"
          });
          this.toastr.errorToastr(err1, 'Error!')
        } else {
          this.toastr.errorToastr(errorResponse.error.message, 'Error!')
        }
      }
    )
  }


}

