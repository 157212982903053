import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';


const baseUrl = environment.apiBaseUrl;

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  getUserDetails(email: string, role: string, limit:number, offset:number) {
    let getUserDetails: string = baseUrl + `/super-admin/listAllUsers?role=${role}&email=${email}&limit=${limit}&offset=${offset}`;
    return this.http.get(getUserDetails);
  }

  update(userRequest: any){
    return this.http.put(baseUrl+ "/super-admin/users", userRequest);
  }

  quizUserList(){
    return this.http.get(baseUrl+`/admin/quiz/user-info`);
  }

  quizUserListByFilter(emailOrThreshold: string){
    return this.http.get(baseUrl+`/admin/quiz/user-info-filter?emailOrThreshold=${emailOrThreshold}`);
  }



}