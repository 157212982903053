import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';


import { Observable, BehaviorSubject } from 'rxjs';
import { FileQueueObject, FileUploaderService } from '../services/file-uploader.service';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'file-uploader, [file-uploader]',
  templateUrl: 'file-uploader.component.html',
  styleUrls: ['./file-uploader.component.css']
})

export class FileUploaderComponent implements OnInit {

  @Output() onCompleteItem = new EventEmitter();

  @ViewChild('fileInput') fileInput;
  queue: Observable<FileQueueObject[]>;


  isFormValid = true;
  uploadForm = null;

  constructor(public uploader: FileUploaderService, public fb: FormBuilder) { }

  ngOnInit() {
    this.queue = this.uploader.queue;
    this.uploader.onCompleteItem = this.completeItem;

    this.uploadForm = this.fb.group({
      name: ['', Validators.required],

    });
  }
  onChange(event: any) {

    let name = this.uploadForm.controls.name.value;
    if (name != '') {
      this.isFormValid = !this.uploader.isFileExistInQueue();
    } else {
      this.isFormValid = true
    }
    console.log()
  }
  completeItem = (item: FileQueueObject, response: any) => {
    this.onCompleteItem.emit({ item, response });
  }

  addToQueue() {
    const fileBrowser = this.fileInput.nativeElement;
    this.uploader.addToQueue(fileBrowser.files, this.uploadForm.controls.name.value);

    let name = this.uploadForm.controls.name.value;
    if (name != '') {
      this.isFormValid = !this.uploader.isFileExistInQueue();
    }
    
  }

  clearQueue() {
    this.uploader.clearQueue();
    let name = this.uploadForm.controls.name.value;
    if (name != '') {
      this.isFormValid = !this.uploader.isFileExistInQueue();
    }
  }
  uploadAll() {
    this.uploader.uploadAll();
  }

}