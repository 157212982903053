import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { SignupComponent } from './signup/signup.component';
import { SignupConfirmationComponent } from './signup-confirmation/signup.confirmation.component';
import { SigninComponent } from './signin/signin.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuardService } from './services/auth.guard.service';
import { SignOutComponent } from './signout/signout.component';
import { UploadAudioComponent } from './upload-audio/upload.audio.component';
import { ExperimentComponent } from './experiment/experiment.component';
import { ExperimentListComponent } from './experiment-list/experiment.list.component';
import { ExperimentEditComponent } from './experiment-edit/experiment.edit.component';
import { ExperimentViewComponent } from './experiment-view/experiment.view.component';
import { PlayerDetailsComponent } from './player-details/player.details.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserEditComponent } from './user-edit/user.edit.component';
import { ForgotPasswordComponent } from './forgot-password/forgot.password.component';
import { ResetPasswordComponent } from './reset-password/reset.password.component';
import { GamePlayingDetailsComponent } from './game-playing-details/game.playing.details.component';
import { QuizUserListComponent } from './quiz-user-info/quiz-user-list.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'signup/confirmation/:token', component: SignupConfirmationComponent },
  { path: 'signin', component: SigninComponent },
  {
    path: 'dashboard', component: DashboardComponent,
    canActivate: [AuthGuardService]
  },
  { path: 'signout', component: SignOutComponent },
  { path: 'upload-audio', component: UploadAudioComponent },
  { path: 'experiment', component: ExperimentComponent },
  { path: 'experiments', component: ExperimentListComponent },
  { path: 'experiments/edit/:experiment', component: ExperimentEditComponent },
  { path: 'experiments/view/:experiment', component: ExperimentViewComponent },
  { path: 'player-details', component: PlayerDetailsComponent },
  { path: 'user-management', component: UserListComponent },
  { path: 'user-management/edit', component: UserEditComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path : 'reset-password/:token', component : ResetPasswordComponent},
  { path : 'game-playing-details', component : GamePlayingDetailsComponent},
  { path : 'quiz/users', component : QuizUserListComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
