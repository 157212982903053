import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { SignupComponent } from './signup/signup.component';
import { ToastrModule } from 'ng6-toastr-notifications';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SignupConfirmationComponent } from './signup-confirmation/signup.confirmation.component';
import { SigninComponent } from './signin/signin.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SignOutComponent } from './signout/signout.component';
import { UploadAudioComponent } from './upload-audio/upload.audio.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { ExperimentComponent } from './experiment/experiment.component';
import { authInterceptorProviders } from './services/auth.interceptor';
import { ExperimentListComponent } from './experiment-list/experiment.list.component';

import {MatTableModule} from '@angular/material/table';
import {MatSelectModule} from '@angular/material/select';
import {
  MatButtonModule,
  MatCommonModule,
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
} from '@angular/material';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { LoadingComponent } from './loader/loading.component';
import { AppDialogComponent } from './dialog/app.dialog.component';
import { ExperimentEditComponent } from './experiment-edit/experiment.edit.component';
import { ExperimentViewComponent } from './experiment-view/experiment.view.component';
import { PlayerDetailsComponent } from './player-details/player.details.component';
import { DatePipe } from '@angular/common';
import { UserListComponent } from './user-list/user-list.component';
import { UserEditComponent } from './user-edit/user.edit.component';
import { ForgotPasswordComponent } from './forgot-password/forgot.password.component';
import { ResetPasswordComponent } from './reset-password/reset.password.component';
import { GamePlayingDetailsComponent } from './game-playing-details/game.playing.details.component';
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';
import { QuizUserListComponent } from './quiz-user-info/quiz-user-list.component';

export default class CustomUrlSerializer implements UrlSerializer {
  private _defaultUrlSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();

  parse(url: string): UrlTree {
      // Encode "+" to "%2B"
      url = url.replace(/\+/gi, '%2B');
      // Use the default serializer.
      return this._defaultUrlSerializer.parse(url);
  }

  serialize(tree: UrlTree): string {
      return this._defaultUrlSerializer.serialize(tree).replace(/\+/gi, '%2B');
  }
}


@NgModule({
  
  declarations: [
    AppComponent,
    HomeComponent,
    SignupComponent,
    SignupConfirmationComponent,
    SigninComponent,
    DashboardComponent,
    SignOutComponent,
    UploadAudioComponent,
    FileUploaderComponent,
    ExperimentComponent,
    ExperimentListComponent,
    ExperimentEditComponent,
    ExperimentViewComponent,
    LoadingComponent,
    AppDialogComponent,
    PlayerDetailsComponent,
    UserListComponent,
    UserEditComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    GamePlayingDetailsComponent,
    QuizUserListComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    FormsModule,
    MatButtonModule,
    MatCommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    ToastrModule.forRoot()
  ],
  providers: [authInterceptorProviders,DatePipe,
    { provide: UrlSerializer, useClass: CustomUrlSerializer }],
  bootstrap: [AppComponent],
  entryComponents:[AppDialogComponent]
})
export class AppModule { }
