import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { SignUpService } from '../services/signup.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { ExperimentService } from '../services/experiment.service';

@Component({
  selector: 'app-experiment-view',
  templateUrl: './experiment.view.component.html',
  styleUrls: ['./experiment.view.component.css']
})
export class ExperimentViewComponent implements OnInit {

  audioFiles = null;

  experimentForm = null;

  toppings = new FormControl();

  toppingList: string[] = ['File 1', 'File 2', 'File 3', 'File 4', 'File 5', 'File 6'];


  constructor(private fb: FormBuilder,
    public toastr: ToastrManager, public router: Router,
    public experimentService: ExperimentService,
    private route: ActivatedRoute) { }
  ngOnInit() {


    this.experimentForm = this.fb.group({
      experimentName: ['', Validators.required],
      master_volume: ['', Validators.required],
      enemy_spawn_multiplier: ['', Validators.required],
      enemy_spawn_multiplier_delta: ['', Validators.required],
      delta_angle_value: ['', Validators.required],

      enemy_start_speed: ['', Validators.required],
      ondeath_level_penalty: ['', Validators.required],
      interval_clips: ['', Validators.required],
      enemy_sound_volume: ['', Validators.required],

      allow_boss_mode: ['', Validators.required],
      points_for_new_level: ['', Validators.required],
      level_diff_ship_upgrade: ['', Validators.required],
      level_diff_enemy_upgrade: ['', Validators.required],

      enemy_speed_increment_per_level: ['', Validators.required],

      ondeath_points_penalty_percentage: ['', Validators.required],
      player_start_level_difference: ['', Validators.required],

      enemy1AudioJsonFile: ['', Validators.required],
      enemy2AudioJsonFile: ['', Validators.required],
      enemy3AudioJsonFile: ['', Validators.required],
      enemy4AudioJsonFile: ['', Validators.required],
      termsAndConditions: ['', Validators.required],
      notifyExperimenterEmails: ['', Validators.required],
      remindPlayers: ['', Validators.required],
      weeklyReminderMaxDuration: ['', Validators.required],
      maxPlayTime: ['', Validators.required],
      redirectUrl: ['', Validators.required]
    });



    this.experimentForm.get('enemy1AudioJsonFile').disable();
    this.experimentForm.get('enemy2AudioJsonFile').disable();
    this.experimentForm.get('enemy3AudioJsonFile').disable();
    this.experimentForm.get('enemy4AudioJsonFile').disable();
    this.experimentService.getAudioFiles().subscribe((data: any) => {
      this.audioFiles = data;

    },
      errorResponse => {
        console.log(errorResponse);
        this.toastr.errorToastr(errorResponse.error.message, 'Error!')
      }
    );


    let experiment = this.route.snapshot.params.experiment;
    this.experimentService.get(experiment).subscribe((data: any) => {
      console.log(data);
      this.experimentForm.setValue({
        experimentName: data.experimentName,
        master_volume: data.master_volume,
        enemy_spawn_multiplier: data.enemy_spawn_multiplier,
        enemy_spawn_multiplier_delta: data.enemy_spawn_multiplier_delta,
        delta_angle_value: data.delta_angle_value,

        enemy_start_speed: data.enemy_start_speed,
        ondeath_level_penalty: data.ondeath_level_penalty,
        interval_clips: data.interval_clips,
        enemy_sound_volume: data.enemy_sound_volume,

        allow_boss_mode: data.allow_boss_mode,
        points_for_new_level: data.points_for_new_level,
        level_diff_ship_upgrade: data.level_diff_ship_upgrade,
        level_diff_enemy_upgrade: data.level_diff_enemy_upgrade,

        enemy_speed_increment_per_level: data.enemy_speed_increment_per_level,

        ondeath_points_penalty_percentage: data.ondeath_points_penalty_percentage,
        player_start_level_difference: data.player_start_level_difference,

        enemy1AudioJsonFile: data.enemy1AudioJsonFile,
        enemy2AudioJsonFile: data.enemy2AudioJsonFile,
        enemy3AudioJsonFile: data.enemy3AudioJsonFile,
        enemy4AudioJsonFile: data.enemy4AudioJsonFile,
        termsAndConditions: data.termsAndConditions,
        notifyExperimenterEmails: data.notifyExperimenterEmails,
        remindPlayers: data.remindPlayers,
        weeklyReminderMaxDuration: data.weeklyReminderMaxDuration,
        maxPlayTime: data.maxPlayTime,
        redirectUrl: data.redirectUrl
      });

    },
      errorResponse => {
        console.log(errorResponse);
        this.toastr.errorToastr(errorResponse.error.message, 'Error!')
      }
    );
  }

  submit() {
    console.log("data" + this.experimentForm.value)
    this.experimentService.update(this.experimentForm.value).subscribe((data: any) => {
      this.toastr.successToastr('Experiment Updated Successfully', 'Success!');
      this.router.navigate(['experiments']);
    },
      errorResponse => {
        console.log(errorResponse);
        let err1 = "";

        if (errorResponse.error.fieldErrors != null) {
          errorResponse.error.fieldErrors.forEach(function (err) {
            err1 = err1 + err.fieldError + "\n"
          });
          this.toastr.errorToastr(err1, 'Error!')

        } else {
          this.toastr.errorToastr(errorResponse.message, 'Error!')
        }


      }
    )
  }


}

