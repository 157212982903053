import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


const baseUrl = environment.apiBaseUrl+ '/api';

@Injectable({
  providedIn: 'root'
})
export class SignUpService {

  constructor(private http: HttpClient) { }

  signup(data) {
    return this.http.post(baseUrl+ "/signup", data);
  }

  signOut(){
    return this.http.post(baseUrl+ "/signup", null);
  }

  confirmUserAccount(token){
    return this.http.get(baseUrl + "/" + "confirm-account?token="+ token);
  }

  
}
