import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';


const baseUrl = environment.apiBaseUrl;

@Injectable({
  providedIn: 'root'
})
export class PlayerService {

  constructor(private http: HttpClient) { }

  getPlayingDetails(playingDetailsRequest: any, limit: number, offset: number) {
    if (playingDetailsRequest.dateStart == null) {
      playingDetailsRequest.dateStart = '';
    }
    if (playingDetailsRequest.dateEnd == null) {
      playingDetailsRequest.dateEnd = '';
    }
    if (playingDetailsRequest.email == null) {
      playingDetailsRequest.email = '';
    }

    if (playingDetailsRequest.experimentCode == null) {
      playingDetailsRequest.experimentCode = '';
    }

    let getPlayingDetailsUrl: string = baseUrl + `/player/play?email=${encodeURIComponent(playingDetailsRequest.email)}&experimentCode=${playingDetailsRequest.experimentCode}
      &dateStart=${playingDetailsRequest.dateStart}&dateEnd=${playingDetailsRequest.dateEnd}&limit=${limit}&offset=${offset}`;
    return this.http.get(getPlayingDetailsUrl);
  }

  getPlayerDetails(playerDetailsRequest: any, limit: number, offset: number) {
    if (playerDetailsRequest.playerId == null) {
      playerDetailsRequest.playerId = '';
    }
    if (playerDetailsRequest.playerEmailAddress == null) {
      playerDetailsRequest.playerEmailAddress = '';
    }
    if (playerDetailsRequest.experimentCode == null) {
      playerDetailsRequest.experimentCode = '';
    }
    if (playerDetailsRequest.dateStart == null) {
      playerDetailsRequest.dateStart = '';
    }

    if (playerDetailsRequest.dateEnd == null) {
      playerDetailsRequest.dateEnd = '';
    }
    let getPlayerDetailsUrl: string = baseUrl + `/playerActivityLogs?playerId=${playerDetailsRequest.playerId}&playerEmailAddress=${encodeURIComponent(playerDetailsRequest.playerEmailAddress)}
        &experimentCode=${playerDetailsRequest.experimentCode}&dateStart=${playerDetailsRequest.dateStart}&dateEnd=${playerDetailsRequest.dateEnd}&limit=${limit}&offset=${offset}`;
    return this.http.get(getPlayerDetailsUrl);
  }

  downloadPlayerDetails(playerDetailsRequest: any) {
    let downloadPlayerDetailsUrl: string = baseUrl + `/download/playerActivityLogs?playerId=${playerDetailsRequest.playerId}&playerEmailAddress=${playerDetailsRequest.playerEmailAddress}
        &experimentCode=${playerDetailsRequest.experimentCode}&dateStart=${playerDetailsRequest.dateStart}&dateEnd=${playerDetailsRequest.dateEnd}`;
    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'text/csv; charset=utf-8');

    return this.http.get(downloadPlayerDetailsUrl, {
      headers: headers,
      observe: 'response',
      responseType: 'text'
    });
  }


}