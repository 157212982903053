import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


const baseUrl = environment.apiBaseUrl;

@Injectable({
  providedIn: 'root'
})
export class PasswordService {

  constructor(private http: HttpClient) { }

  forgotPassword(data) {
    return this.http.post(`${baseUrl}/api/forgotPassword`, data);
  }


  confirmPassword(token:string, data:any) {
    return this.http.post(`${baseUrl}/api/confirm-password/${token}`, data);
  }





  
}
