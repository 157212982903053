import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { SignInService } from '../services/signin.service';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { PasswordService } from '../services/password.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot.password.component.html'
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm = null;

  constructor(private fb: FormBuilder, private service: PasswordService,
    public toastr: ToastrManager, public router: Router, public authService: AuthService) { }
  ngOnInit() {

    this.forgotPasswordForm = this.fb.group({
      email: ['', Validators.required],
    });
  }

  forgotPasswordSubmit() {
    this.service.forgotPassword(this.forgotPasswordForm.value).subscribe((data: any) => {
      this.toastr.successToastr('Password Reset Link has been sent to you email', 'Success!');
      this.router.navigate(['signin']);
    },
      errorResponse => {
        let err1 = "";
        if (errorResponse.error.fieldErrors != null && errorResponse.error.fieldErrors.length > 0) {
          errorResponse.error.fieldErrors.forEach(function (err) {
            err1 = err1 + err.fieldError + "\n"
          });
          this.toastr.errorToastr(err1, 'Error!')
        } else {
          this.toastr.errorToastr(errorResponse.error.message, 'Error!')
        }
      }
    )
  }


}

