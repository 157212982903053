import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router';
import { PlayerService } from '../services/player.service';
import { DatePipe } from '@angular/common';
import * as fileSaver from 'file-saver'

@Component({
  selector: 'app-player-details',
  templateUrl: './player.details.component.html',
  styleUrls: ['./player.details.component.css']
})
export class PlayerDetailsComponent implements OnInit {

  playerDetailsForm = null;
  playerDetails = null;

  playerDetailsCount: number = 0;
  playCounter: number = 0;
  limit: number = 4;
  offset: number = 1;
  prevF: boolean = true;
  firstF: boolean = true;
  nextF: boolean = false;
  lastF: boolean = false;

  showPagination: boolean = false;

  constructor(private fb: FormBuilder,
    public toastr: ToastrManager, public router: Router,
    public playerService: PlayerService,
    private datePipe: DatePipe) { }


  ngOnInit() {
    this.playerDetailsForm = this.fb.group({
      playerEmailAddress: ['', Validators.required],
      playerId: ['', Validators.required],
      experimentCode: ['', Validators.required],
      dateStart: ['', Validators.required],
      dateEnd: ['', Validators.required],

    });

  }

  download() {
    this.playerService.downloadPlayerDetails(this.playerDetailsForm.value)
      .subscribe(response => {
        let fileName = "player-activity-logs-" + this.datePipe.transform(new Date(),'yyyy-MM-dd-hh:mm')+".csv";
        this.saveFile(response.body, fileName);
      },
        errorResponse => {
          console.log(errorResponse.error);
          this.toastr.errorToastr(errorResponse.error.message, 'Error!')
        }
      );
  }

  saveFile(data: any, filename?: string) {
    const blob = new Blob([data], { type: 'text/csv; charset=utf-8' });
    fileSaver.saveAs(blob, filename);
  }

  submit(flag:boolean) {
    console.log("data" + this.playerDetailsForm.value)
    if (flag) {
      this.offset = 1;
      this.prevF=true;
      this.firstF=true;
      this.lastF = false;
        this.nextF = false;
    }
    this.playerService.getPlayerDetails(this.playerDetailsForm.value, this.limit, this.offset).subscribe((data: any) => {
      this.playerDetails = data.responses;
      this.playerDetailsCount = data.pages;
      if (this.playerDetailsCount > 0) {
        this.showPagination = true;
        if (this.limit >= (this.playerDetailsCount * this.limit)) {
          this.lastF = true;
          this.nextF = true;
        }
      } else {
        this.showPagination = false;
        this.firstF = true;
        this.prevF = true;
        this.lastF = true;
        this.nextF = true;
      }
    },
      errorResponse => {
        console.log(errorResponse.error);
        this.toastr.errorToastr(errorResponse.error.message, 'Error!')
      }
    )
  }
  first() {
    this.playCounter = 1;
    this.offset = 1;
    this.firstF = true;
    this.prevF = true;
    this.submit(false);
    if (this.lastF) {
      this.lastF = false;
    }
    if (this.nextF) {
      this.nextF = false;
    }
  }
  last() {
    this.playCounter = this.playerDetailsCount;
    this.offset = this.playCounter;
    this.lastF = true;
    this.nextF = true;
    this.submit(false);
    if (this.offset > 1) {
      this.firstF = false;
      this.prevF = false;
    }
  }
  next() {
    if (this.offset < this.playerDetailsCount) {
      this.offset = this.offset + 1;
      this.submit(false);
    } else {
      this.nextF = true;
      this.lastF = true;
    }
    if (this.offset == this.playerDetailsCount) {
      this.nextF = true;
      this.lastF = true;
    }

    if (this.offset > 1) {
      this.firstF = false;
      this.prevF = false;
    }


  }
  prev() {
    if (this.offset != 1) {
      this.offset = this.offset - 1;
      this.submit(false);
    } else {
      this.firstF = true;
      this.prevF = true;
    }
    if(this.offset == 1){
      this.firstF = true;
      this.prevF = true;
    }
    if (this.offset < this.playerDetailsCount) {
      this.nextF = false;
      this.lastF = false;
    }

  }

}

