import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SignUpService } from '../services/signup.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router';
import { ExperimentService } from '../services/experiment.service';
import { MatDialog } from '@angular/material';
import { AppDialogComponent } from '../dialog/app.dialog.component';


export interface PeriodicElement {
  experimentName: string;
  experimentSerial: number;
  enemy1AudioJsonFile: string;
  enemy2AudioJsonFile: string;
  enemy3AudioJsonFile: string;
  enemy4AudioJsonFile: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { experimentSerial: 1, experimentName: 'Experiment 1', enemy1AudioJsonFile: "Audio file", enemy2AudioJsonFile: "Audio file 2", enemy3AudioJsonFile: "Audio file 3", enemy4AudioJsonFile: "Audio file 4" },
  { experimentSerial: 2, experimentName: 'Experiment 2', enemy1AudioJsonFile: "Audio file", enemy2AudioJsonFile: "Audio file 2", enemy3AudioJsonFile: "Audio file 3", enemy4AudioJsonFile: "Audio file 4" },
  { experimentSerial: 3, experimentName: 'Experiment 3', enemy1AudioJsonFile: "Audio file", enemy2AudioJsonFile: "Audio file 2", enemy3AudioJsonFile: "Audio file 3", enemy4AudioJsonFile: "Audio file 4" },
  { experimentSerial: 4, experimentName: 'Experiment 4', enemy1AudioJsonFile: "Audio file", enemy2AudioJsonFile: "Audio file 2", enemy3AudioJsonFile: "Audio file 3", enemy4AudioJsonFile: "Audio file 4" },
  { experimentSerial: 5, experimentName: 'Experiment 5', enemy1AudioJsonFile: "Audio file", enemy2AudioJsonFile: "Audio file 2", enemy3AudioJsonFile: "Audio file 3", enemy4AudioJsonFile: "Audio file 4" },
];


@Component({
  selector: 'app-experiment-list',
  templateUrl: './experiment.list.component.html',
  styleUrls: ['./experiment.list.component.css']
})
export class ExperimentListComponent implements OnInit {
  displayedColumns: string[] = ['experimentSerial', 'experimentName', 'enemy1AudioJsonFile', 'enemy2AudioJsonFile', 'enemy3AudioJsonFile', 'enemy4AudioJsonFile'];
  dataSource = ELEMENT_DATA;

  experimentList = null;
  constructor(private experimentService: ExperimentService, 
    public dialog: MatDialog,
    public toastr: ToastrManager, public router: Router) {

  }
  ngOnInit() {
    this.experimentService.list().subscribe((data: any) => {
      this.experimentList = data;
    },
      errorResponse => {
        console.log(errorResponse);
      }
    )

  }




  addNewExperiment(){
    this.router.navigate(['experiment']);
  }

  openDialog(experiment: any): void {
    let dialogRef = this.dialog.open(AppDialogComponent, {
      width: '250px',
      data: { name: experiment, header: 'Are you sure to delete ' + experiment + ' ?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes') {
        this.experimentService.delete(experiment).subscribe((data: any) => {
          this.toastr.successToastr('Experiment Deleted Successfully', 'Success!');
          window.location.reload();
        },
        errorResponse => {
          this.toastr.errorToastr('Experiment Not Deleted Successfully', 'Error!');
        }
        )
      }

    });
  }
}

