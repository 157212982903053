import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SignUpService } from '../services/signup.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-signup-confirmation',
  templateUrl: './signup.confirmation.component.html',
  styleUrls: ['./signup.confirmation.component.css']
})
export class SignupConfirmationComponent implements OnInit {

  isUserConfirmed = null;
  errorMessage = '';
  constructor(private route: ActivatedRoute, private service: SignUpService, public toastr: ToastrManager) { }

  ngOnInit() {
    let token = this.route.snapshot.params.token;
    this.service.confirmUserAccount(token).subscribe(data => {
      console.log(data);
      this.isUserConfirmed = true;
    },
      errorResponse => {
        this.errorMessage = errorResponse.error.message;
        this.isUserConfirmed = false;

      }
    )
  }




}

