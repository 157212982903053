import { Component } from '@angular/core';

@Component({
  selector: 'app',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {
  title = 'Dashboard';

  isSuperAdmin(){
    let roles: any = localStorage.getItem('role') 
    return roles != null && roles.includes('ROLE_SUPERADMIN');
  }
}
