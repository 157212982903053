import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router';
import { PlayerService } from '../services/player.service';
import { DatePipe } from '@angular/common';
import * as fileSaver from 'file-saver'
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-quiz-user-list',
  templateUrl: './quiz-user-list.component.html',
  styleUrls: ['./quiz-user-list.component.css']
})
export class QuizUserListComponent implements OnInit {

  quizUserList = null;
  quizDetailsForm = null;
  constructor(private fb: FormBuilder,
    public toastr: ToastrManager, public router: Router,
    public userService: UserService) { }


  ngOnInit() {
   
    this.quizDetailsForm = this.fb.group({
      emailOrThreshold: ['', Validators.required]
    });

    this.userService.quizUserList().subscribe((data: any) => {
      this.quizUserList = data.items;
    },
      errorResponse => {
        console.log(errorResponse.error);
        this.toastr.errorToastr(errorResponse.error.message, 'Error!')
      }
    );
  }


  submit(){
    this.userService.quizUserListByFilter(this.quizDetailsForm.controls.emailOrThreshold.value).subscribe((data: any) => {
      this.quizUserList = data.items;
    },
      errorResponse => {
        console.log(errorResponse.error);
        this.toastr.errorToastr(errorResponse.error.message, 'Error!')
      }
    );
  }
 

}

