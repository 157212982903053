import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";


const baseUrl = environment.apiBaseUrl+ '/api';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient) { }

  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // ...

  signOut(){
    return this.http.post(baseUrl+ "/logout", null);
  }

  public getToken(): string{
    return localStorage.getItem('token');
  }
  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');

    return token != null;
  }


   setUserLoggedIn(){
    this.loggedIn.next(true);
  }

  get isUserLoggedIn() {
    if (this.isAuthenticated()) {
      this.loggedIn.next(true);
    } else {
      this.loggedIn.next(false);
    }

    return this.loggedIn.asObservable();
  }

  public removeLocalStorage(){
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("role");
    this.loggedIn.next(false);
  }
}