import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { SignInService } from '../services/signin.service';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {
  signInForm = null;

  constructor(private fb: FormBuilder, private service: SignInService,
    public toastr: ToastrManager, public router: Router, public authService: AuthService) { }
  ngOnInit() {

    this.signInForm = this.fb.group({

      email: ['', Validators.required],
      password: ['', Validators.required],

    });
  }

  signupSubmit() {
    this.service.signIn(this.signInForm.value).subscribe((data: any) => {
      console.log(data);
      localStorage.setItem("email", data.email)
      localStorage.setItem("role", data.roles)
      localStorage.setItem("token", data.token);
      this.authService.setUserLoggedIn();
      this.router.navigate(['dashboard']);

    },
      errorResponse => {

        let err1 = "";
        if (errorResponse.error.fieldErrors != null && errorResponse.error.fieldErrors.length > 0) {
          errorResponse.error.fieldErrors.forEach(function (err) {
            err1 = err1 + err.fieldError + "\n"
          });
          this.toastr.errorToastr(err1, 'Error!')
        } else {
          this.toastr.errorToastr(errorResponse.error.message, 'Error!')
        }





      }
    )
  }


}

