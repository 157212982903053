import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


const baseUrl = environment.apiBaseUrl;

@Injectable({
    providedIn: 'root'
})
export class ExperimentService {

    constructor(private http: HttpClient) { }

    getAudioFiles() {
        return this.http.get(baseUrl + '/admin/searchExperimentAudio');
    }

    save(data: any) {
        return this.http.post(baseUrl + '/admin/createExperiment', data);
    }

    list() {
        return this.http.get(baseUrl + '/admin/listAllExperiment');
    }

    delete(experimentname: string) {
        return this.http.delete(baseUrl + '/admin/deleteExperiment/' + experimentname);
    }

    get(experimentname: string) {
        return this.http.get(baseUrl + '/admin/getExperiment/' + experimentname);
    }

    update(data:any){
        return this.http.put(baseUrl + '/admin/updateExperiment', data);  
    }
}