import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SignUpService } from '../services/signup.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  signup = null;

  constructor(private fb: FormBuilder, private service: SignUpService,
    public toastr: ToastrManager, public router: Router) { }
  ngOnInit() {

    this.signup = this.fb.group({
      fullName: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      dateOfBirth: ['', Validators.required],
      experimentCode: ['', Validators.required],
      terms:
        ['', Validators.required],

    });
  }

  signupSubmit() {
    this.service.signup(this.signup.value).subscribe((data: any) => {
      console.log(data);
      this.toastr.successToastr('Check your Email and confirm', 'Success!');
      this.router.navigate(['signin']);
    },
      errorResponse => {
        console.log(errorResponse.error.fieldErrors);
        let err1 = "";

        errorResponse.error.fieldErrors.forEach(function (err) {
          err1 = err1 + err.fieldError + "\n"
        });
        this.toastr.errorToastr(err1, 'Error!')
      }
    )
  }


}

