import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {

   
    private static loaderEnabled: boolean;

    constructor() { }
  
    get loaderEnabled() {
      return LoaderService.loaderEnabled;
    }
  
    public static showLoader() {
      LoaderService.loaderEnabled = true;
    }
  
    public static hideLoader() {
      LoaderService.loaderEnabled = false;
    }
}


export function LoaderEnabled(): MethodDecorator {

    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
      const original = descriptor.value;
  
      descriptor.value = function () {
  
        LoaderService.showLoader();
        console.log('**InjectedCode-begin--LOADERON', propertyKey);
  
        return original.apply(this, arguments)
          .pipe(
            map((res) => {
              console.log('**InjectedCode-map--LOADEROFF', propertyKey);
              LoaderService.hideLoader();
              return res;
            }),
            catchError((err) => {
              console.log('**InjectedCode-err--LOADEROFF', propertyKey);
              LoaderService.hideLoader();
              throw err;
            })
          );
      };
      return descriptor;
    };
  
  }