import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { SignUpService } from '../services/signup.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router';
import { ExperimentService } from '../services/experiment.service';

@Component({
  selector: 'app-experiment',
  templateUrl: './experiment.component.html',
  styleUrls: ['./experiment.component.css']
})
export class ExperimentComponent implements OnInit {

  audioFiles = null;

  experimentForm = null;

  toppings = new FormControl();

  toppingList: string[] = ['File 1', 'File 2', 'File 3', 'File 4', 'File 5', 'File 6'];


  constructor(private fb: FormBuilder,
    public toastr: ToastrManager, public router: Router, public experimentService: ExperimentService) { }
  ngOnInit() {


    this.experimentForm = this.fb.group({
      experimentName: ['', Validators.required],
      master_volume: ['', Validators.required],
      enemy_spawn_multiplier: ['', Validators.required],
      enemy_spawn_multiplier_delta: ['', Validators.required],
      delta_angle_value: ['', Validators.required],

      enemy_start_speed: ['', Validators.required],
      ondeath_level_penalty: ['', Validators.required],
      interval_clips: ['', Validators.required],
      enemy_sound_volume: ['', Validators.required],

      allow_boss_mode: ['', Validators.required],
      points_for_new_level: ['', Validators.required],
      level_diff_ship_upgrade: ['', Validators.required],
      level_diff_enemy_upgrade: ['', Validators.required],

      enemy_speed_increment_per_level: ['', Validators.required],

      ondeath_points_penalty_percentage: ['', Validators.required],
      player_start_level_difference: ['', Validators.required],

      enemy1AudioJsonFile: ['', Validators.required],
      enemy2AudioJsonFile: ['', Validators.required],
      enemy3AudioJsonFile: ['', Validators.required],
      enemy4AudioJsonFile: ['', Validators.required],
      termsAndConditions: ['', Validators.required],
      notifyExperimenterEmails: ['', Validators.required],
      remindPlayers: ['', Validators.required],
      weeklyReminderMaxDuration: ['', Validators.required],
      maxPlayTime:['', Validators.required],
      redirectUrl:['', Validators.required]
    });

    this.experimentService.getAudioFiles().subscribe((data: any) => {
      this.audioFiles = data;
    },
      errorResponse => {
        console.log(errorResponse);
        this.toastr.errorToastr(errorResponse.error.message, 'Error!')
      }
    )
  }

  submit() {
    console.log("data" + this.experimentForm.value)
    this.experimentService.save(this.experimentForm.value).subscribe((data: any) => {
      this.toastr.successToastr('Experiment Created Successfully', 'Success!');
      this.router.navigate(['experiments']);
    },
      errorResponse => {
        console.log(errorResponse.error.fieldErrors);
        let err1 = "";

        if (errorResponse.error.fieldErrors != null && errorResponse.error.fieldErrors.length > 0) {
          errorResponse.error.fieldErrors.forEach(function (err) {
            err1 = err1 + err.fieldError + "\n"
          });
          this.toastr.errorToastr(err1, 'Error!')
        }else{
          this.toastr.errorToastr(errorResponse.error.message, 'Error!')
        }
        
        
      }
    )
  }


}

